<!-- 二维码已过期 -->
<template>
  <div class="ercode">
    <img :src="img" />
    <h2>二维码已过期</h2>
    <div></div>
    <a href="http://www.cqsqy.com"> ({{ time }})秒后跳转</a>
  </div>
</template>

<script>
export default {
  data() {
    return {
      time: 5,
      img: require("@/assets/images/warning.png"),
    };
  },
  mounted() {
    let t = setInterval(() => {
      if (this.time != 1) {
        this.time--;
      } else {
        clearInterval(t);
        window.location.replace("http://www.cqsqy.com");
      }
    }, 1000);

    // this.$message({
    //   showClose: true,
    //   message: "二维码已过期!",
    //   type: "error",
    //   duration: 2000,
    //   onClose: () => {
    //     window.location.replace("http://www.cqsqy.com");
    //   },
    // });
  },
};
</script>
<style lang='less' scoped>
.ercode {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;

  img {
    width: 100%;
    height: 300px;
  }
}
</style>